const configuration = {
    iceServers: [
        {
            urls: "stun:stun.relay.metered.ca:80",
        },
        {
            urls: "turn:159.223.175.154:3478",
            username: "user",
            credential: "password"
        },
        {
            urls: "turn:95.217.13.89:3478",
            username: "user",
            credential: "password"
        }
    ]
};

export default configuration
