import React, { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import io from 'socket.io-client';
import './Player.css';
import rtcConfig from './webrtc/RTCConfig';
import rtcSignalTypes from './webrtc/RTCSignalTypes';
import rtcCallOptions from './webrtc/RTCCallOptions';
import utils from './Utils';

const { SERVER_PORT, SERVER_IP } = require('./ClientConfig');

const Player = () => {
    const { android_id } = useParams();
    const [isVideoPlaying, setIsVideoPlaying] = useState(false);
    const [isSettingsVisible, setIsSettingsVisible] = useState(false);
    const [ping, setPing] = useState(null);
    const [isRestartButtonDisabled, setIsRestartButtonDisabled] = useState(false);
    const [peerConnectionState, setPeerConnectionState] = useState('connecting'); // New state for peer connection status
    const videoRef = useRef(null);
    const socketRef = useRef(null);

    let peerConnection;

    useEffect(() => {
        socketRef.current = io(`https://reactneuroglia.com`, {
            query: { android_id, platform: 'website' }
        });

        socketRef.current.on('connect', () => {
            console.log(`Player connected with android_id: ${android_id}`);
            // setPeerConnectionState('connecting'); // Set initial state to connecting
        });

        socketRef.current.on('disconnect', () => {
            console.log('Player disconnected from the server!');
            // setPeerConnectionState('disconnected'); // Update state when disconnected
        });

        socketRef.current.on('message', async (data) => {
            await handleMessage(socketRef.current, data);
        });

        return () => {
            socketRef.current.disconnect();
        };
    }, [android_id]);

    const handleMessage = async (socket, message) => {
        const incomingData = JSON.parse(message);
        if (incomingData.type !== rtcSignalTypes.Ping) {
            console.log('Incoming message:', message);
        }
        switch (incomingData.type) {
            case rtcSignalTypes.TargetConnected:
                sendMessageToSocket(socket, { type: rtcSignalTypes.Start });
                break;
            case rtcSignalTypes.Start:
                await restartPeerConnection(socket);
                const offer = await peerConnection.createOffer(rtcCallOptions);
                await peerConnection.setLocalDescription(offer);
                sendMessageToSocket(socket, { type: rtcSignalTypes.Offer, data: offer });
                disableRestartButton(false);
                break;
            case rtcSignalTypes.Offer:
                await restartPeerConnection(socket);
                await peerConnection.setRemoteDescription({ type: 'offer', sdp: incomingData.data });
                const answer = await peerConnection.createAnswer(rtcCallOptions)
                await peerConnection.setLocalDescription(answer);
                sendMessageToSocket(socket, { type: rtcSignalTypes.Answer, data: answer });
                break;
            case rtcSignalTypes.Answer:
                await peerConnection.setRemoteDescription({ type: 'answer', sdp: incomingData.data });
                break;
            case rtcSignalTypes.ICE:
                const candidate = convertToRTCIceCandidate(incomingData.data);
                if (candidate) {
                    await peerConnection.addIceCandidate(candidate);
                } else {
                    console.error("Failed to convert ICE candidate.");
                }
                break;
            case rtcSignalTypes.Ping:
                setPing(utils.getCurrentTimeInMs(incomingData.data));
                break;
            default:
                console.log('Unhandled message type:', incomingData);
                break;
        }
    };

    const handleRestartButtonClick = () => {
        console.log("Restart button clicked and disabled");
        setPeerConnectionState('disconnected'); // Update state when disconnected
        disableRestartButton(true);
        sendMessageToSocket(socketRef.current, { type: rtcSignalTypes.Restart });
    };

    const disableRestartButton = (disabled) => {
        setIsRestartButtonDisabled(disabled);
    };

    const restartPeerConnection = async (socket) => {
        if (peerConnection) {
            await peerConnection.close();
        }
        peerConnection = new RTCPeerConnection(rtcConfig);
        peerConnection.addEventListener('icegatheringstatechange', () => {
            console.log('ICE gathering state: ', peerConnection.iceGatheringState);
        });

        peerConnection.addEventListener('iceconnectionstatechange', () => {
            console.log('ICE connection state: ', peerConnection.iceConnectionState);
            setPeerConnectionState(peerConnection.iceConnectionState); // Update state based on ICE connection state
        });

        peerConnection.addEventListener('icecandidate', async (e) => {
            console.log("Generated candidate: ", e);
            if (e.candidate) {
                const iceCandidate = {
                    sdpCandidate: e.candidate.candidate,
                    sdpMid: e.candidate.sdpMid === '1' ? 'video' : 'audio',
                    sdpMLineIndex: e.candidate.sdpMLineIndex,
                };
                await peerConnection.addIceCandidate(iceCandidate);
                sendMessageToSocket(socket, {
                    type: rtcSignalTypes.ICE,
                    data: iceCandidate
                });
            }
        });

        peerConnection.addEventListener('track', (event) => {
            if (videoRef.current) {
                videoRef.current.srcObject = event.streams[0];
                videoRef.current.muted = true;
                videoRef.current.play().then(() => {
                    setIsVideoPlaying(true);
                }).catch((error) => {
                    console.error("Autoplay prevented. User interaction needed.", error);
                    setIsVideoPlaying(false);
                });
            }
        });
    };

    const convertToRTCIceCandidate = (candidateObj) => {
        if (!candidateObj || !candidateObj.sdp || !candidateObj.sdpMid || candidateObj.sdpMLineIndex === undefined) {
            console.error("Invalid candidate object structure", candidateObj);
            return null;
        }

        const { sdp, sdpMid, sdpMLineIndex } = candidateObj;

        try {
            return new RTCIceCandidate({
                candidate: sdp,
                sdpMid: sdpMid,
                sdpMLineIndex: sdpMLineIndex
            });
        } catch (error) {
            console.error("Error creating RTCIceCandidate:", error);
            return null;
        }
    };

    const sendMessageToSocket = (socket, data) => {
        if (socket) {
            socket.send(data);
        } else {
            console.error("Socket is not initialized.");
        }
    };

    const handlePlayButtonClick = () => {
        if (videoRef.current) {
            videoRef.current.muted = false;
            videoRef.current.play().then(() => {
                setIsVideoPlaying(true);
            }).catch((error) => {
                console.error("Manual play failed:", error);
            });
        }
    };

    const toggleSettings = () => {
        setIsSettingsVisible(!isSettingsVisible);
    };

    return (
        <div className="video-container">
            <div className={`status-button ${peerConnectionState}`}>
                {peerConnectionState === 'connected' && 'Connected'}
                {peerConnectionState === 'connecting' && 'Connecting...'}
                {peerConnectionState === 'disconnected' && 'Disconnected'}
                {peerConnectionState === 'checking' && 'Checking'}
            </div>
            {!isVideoPlaying && (
                <button onClick={handlePlayButtonClick} className="play-button">
                    Play Video
                </button>
            )}
            <video ref={videoRef} autoPlay playsInline muted className="video-element"></video>
            {!isSettingsVisible && (
                <button onClick={toggleSettings} className="settings-button">
                    ⚙️
                </button>
            )}
            {isSettingsVisible && (
                <div className="settings-panel">
                    <div className="ping-display">
                        Ping: {ping}
                        <button
                            onClick={handleRestartButtonClick}
                            disabled={isRestartButtonDisabled}
                            className="restart-button">
                            Restart Stream
                        </button>
                    </div>
                    <button onClick={toggleSettings} className="close-button">Close</button>
                </div>
            )}
        </div>
    );
};

export default Player;
