const RTCSignalTypes = {
    TargetConnected:'TargetConnected',
    Start : 'Start',
    Offer : 'Offer',
    Answer : 'Answer',
    ICE : 'ICE',
    Ping:'Ping',
    Restart:'Restart',
}

export default RTCSignalTypes