import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Player from './Player';

ReactDOM.render(
    <React.StrictMode>
        <Router>
            <Routes>
                <Route
                    path="/player/:android_id"
                    element={<Player />}
                />
            </Routes>
        </Router>
    </React.StrictMode>,
    document.getElementById('root')
);
